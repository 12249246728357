import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
function  PrivacyPolicy(){
    return(

        <div className="price-page">
            <Container>
                <div className="pricing-title text-center terms-section">
                    <p>Terms of Service</p>
                </div>
                <Row>
                    <Col lg={12} sm={12} xs={12}>

                        <div className="policy-block">

                            <p className="conditions-paragraph">Welcome to Kolpolok Limited.</p>
                            <p>
                                These terms of service comprise the terms and conditions set forth below and any additional terms on 
                                the Site (together “Terms of Service”) and govern your use of the services provided by Kolpolok Limited 
                                (“Kolpolok” or “Symlex Call” or “we”) to you under the brands of Symlex.
                            </p>

                            <h3 className="termstitle">Acceptance of Terms</h3>
                            <p className="conditions-paragraph">
                                You agree to be bound by the Terms of Service if you: (i) access the website symlexcall.com and 
                                such other sites that are operated by Kolpolok Limited from time to time (the “Site”); (ii) download a 
                                Symlex Call application to your mobile device (an "App"); or (iii) use Kolpolok Limited’s services
                            </p>
                            
                            <p className="conditions-paragraph">
                                Kolpolok Limited may without notice renew, modify or amend the Terms of Service from time to time. 
                                If you continue to use the Services you accept such revisions.
                            </p>
                            
                            <h3 className="termstitle">Jurisdictional Restrictions</h3>
                            <p className="conditions-paragraph">
                                If you are residing in a jurisdiction where it is prohibited by law to offer or use Internet telephony or the Services, 
                                you may not use the Services in such jurisdiction.
                            </p>

                            <h3 className="termstitle">Third Party</h3>
                            <p className="conditions-paragraph">
                                Your access to and use of a service provided by a third party service or content provider, including but not limited to 
                                your local mobile operator ("Provider"), may also be subject to the Provider's terms and conditions
                            </p>
                            
                            <h3 className="termstitle">Use of the Services</h3>
                            <p className="conditions-paragraph">
                                You may only use the Services subject to your compliance with these Terms of Service.
                            </p>
                            
                            <h3 className="termstitle">Usernames and password</h3>
                            <p className="conditions-paragraph">
                                In order to use the Services, you are required to register as a user with Symlex Call. 
                                You agree to provide true, accurate, current and complete information in all fields 
                                indicated as mandatory when registering for the Services, as well as any additional 
                                information provided or any amendments made by you. Subject to such registration you 
                                will receive access to your Symlex Call account (the "Account"). Not limiting the aforesaid, 
                                Symlex Call may in certain geographical locations offer you.
                            </p>
                            
                            <h3 className="termstitle">Unauthorized Use/Stolen Device</h3>
                            <p className="conditions-paragraph">
                                As part of signing up on the Site you will choose a password (“password”) required for access to and 
                                use of the Services. You are responsible for all actions that take place as a result of access to 
                                or use of the Services via your password and/or via your mobile phone (“Device”) independently of 
                                whether the access was made by yourself or by a third party using your password and/or Device.
                            </p>
                            <p className="conditions-paragraph">
                                If your Device is stolen or if you become aware of unauthorized use of the Services you must notify us 
                                immediately to suspend the Services and block your Account. You are responsible for all charges to your 
                                Account until the Services are suspended. We have the right to change your password and account name at 
                                any time at our sole discretion and without notice.
                            </p>

                            <h3 className="termstitle">No Unlawful or Prohibited Use</h3>
                            <p className="conditions-paragraph">
                                You expressly acknowledge and agree to use the Services solely for lawful purposes. In this respect you may not, 
                                without limitation (a) intercept or monitor, damage or modify any communication which is not intended for you, 
                                (b) send any unsolicited commercial communication, (c) use the Services in any fraudulent way, (d) 
                                submit or post to the Site or expose any third party to material which infringe any third party’s 
                                intellectual property rights or violate the rights of any third party, is offensive, defamatory, racist, 
                                pornographic, illegal, harmful to minors, indecent or otherwise, in Kolpolok Limited’s sole discretion, 
                                is objectionable. Furthermore you may not use the Services in any manner that could damage, disable, overburden, 
                                or impair any Symlex Call server, or the network(s) connected to any Symlex Call server, or interfere with any 
                                other party's use and enjoyment of the Services.
                            </p>
                            
                            <h3 className="termstitle">Submission of information to Symlex Call</h3>
                            <p className="conditions-paragraph">
                                In the course of using the Service, users may provide information about themselves 
                                which may be visible to certain other users You grant Symlex Call a royalty-free, 
                                world-wide, transferable, sub-licensable, perpetual and irrevocable license to use 
                                any information you post or transmit in the course of using the Services.
                            </p>
                            
                            <h3 className="termstitle">Disclosure of information</h3>
                            <p className="conditions-paragraph">
                                Symlex Call reserves the right at all times to disclose any information as Symlex Call deems necessary to 
                                satisfy any applicable law, regulation, legal process or governmental request, or to edit, refuse to post 
                                or to remove or restrict any information or materials, in whole or in part, in Symlex Call’s sole discretion
                            </p>
                            
                            <h3 className="termstitle">No warranties</h3>
                            <p className="conditions-paragraph">
                                Symlex Call shall endeavor to provide the Services with minimum disruptions. However, Symlex Call does not make 
                                any express warranty regarding the Services and disclaims any implied warranty, including any warranty 
                                of merchantability, satisfactory quality, or fitness for a particular purpose. Symlex Call cannot 
                                guarantee that the Services will always function without disruptions, delay or other imperfections 
                                and Symlex Call may choose not to provide service to countries or calling areas at its sole discretion. 
                                Symlex Call cannot guarantee transfer of your number.
                            </p>
                            
                            <p className="conditions-paragraph">
                                Since the Services will be transmitted e.g. through public Internet lines and the public switched telephone 
                                network, you acknowledge that there may be power outages or Internet service disruption and you may experience 
                                some disruptions in the Services, e.g. packet loss and delay.
                            </p>
                            
                            <p className="conditions-paragraph">
                                Additionally, you also understand that Symlex Call cannot guarantee that information or communications 
                                sent using the Services are not encrypted and could be potentially subject to interception by law enforcement 
                                officials or other third parties over the public Internet. Symlex Call will not be liable for any disruption, 
                                delays, interception or other omissions in the Services.
                            </p>

                            <h3 className="termstitle">Suspension and Interruption of your Symlex Call Account and the Services</h3>
                            <p className="conditions-paragraph">
                                Any fraudulent use of the Services, or any use for any illegal, improper or unethical purpose is strictly prohibited. 
                                Should Symlex Call find or have reasons to believe that you are liable for any such use, Kolpolok Limited has the right 
                                to cancel your Symlex Call Account immediately, without prior notice to you, and without any obligation to compensate 
                                you for any loss you may suffer due to such cancellation. In addition, Symlex Call may prevent, at any time, in its 
                                sole discretion and without prior notice to you, any and all use of the Services for any reason whatsoever, including 
                                but not limited to, fraudulent patterns in the use of the Service, excessive usage, unlawful use, or any other misuse. 
                                Within such prevention, Kolpolok Limited has the right to suspend your Symlex Call Account should Symlex Call have any 
                                reason to suspect that you misuse the Services. You are fully responsible towards Kolpolok Limited for all 
                                (including any fraudulent) use of the Services which is or can be connected to your Symlex Call Account.
                            </p>
                            <p className="conditions-paragraph">
                                Furthermore, Symlex Call has the right, without any liability, to refuse, restrict, limit, suspend and/or 
                                interfere or interrupt the Services or any part thereof, without any prior notice to you, for the repair, 
                                improvement, and/or upgrade of such service or for any of the reasons for termination set forth in these terms, 
                                or any other business reason.
                            </p>
                            
                            <h3 className="termstitle">Communication</h3>
                            <p className="conditions-paragraph">
                                The content of the communications made using the Services are entirely determined by the person from whom such 
                                content originates. You therefore may be exposed to content that is offensive, harmful, indecent or otherwise 
                                objectionable. Symlex Call will not be liable for any type of communication spread by means of the Services.
                            </p>
                            
                            <h3 className="termstitle">Intellectual property rights</h3>
                            <p className="conditions-paragraph">
                                All copyright, trademarks, patents and other intellectual property rights in any material or content 
                                (including without limitation software, data, applications, information, text, photographs, music, sound, 
                                videos, graphics, logos, symbols, artwork and other material or moving images) used in the provision of the 
                                Services or is either owned by Kolpolok Limited or has been licensed to Kolpolok Limited by the rights owner(s) 
                                for use as part of the Services. Nothing in these Terms of Service shall grant you any intellectual property 
                                rights in the Services or in respect of any content or communication delivered using the Services.
                            </p>
                            
                            <p className="conditions-paragraph">
                                Furthermore, Kolpolok Limited has the right to remove any materials posted to the Site in its sole discretion.
                            </p>
                            
                            <h3 className="termstitle">VOIP SERVICES</h3>
                            <p className="conditions-paragraph">
                                Symlex Call provides you with the possibility to use international calling services and other electronic
                                communication services as provided by Symlex Call from time to time (the "VoIP Services''), on the terms and 
                                conditions set forth in the Terms of Service. The calling services are available from smartphone, landline or feature phone.
                            </p>
                            <h3 className="termstitle">No emergency calls</h3>
                            <p className="conditions-paragraph">
                                The VoIP Services do not and are not intended to support or carry 
                                emergency calls to any type of hospitals, law enforcement agencies, medical care units or any 
                                type of emergency services of any kind and Symlex Call is not liable in any manner for such calls. 
                                In the event that, with your permission, another user uses your Account, you are responsible to 
                                inform that user that it is not possible to support or carry emergency calls with the VoIP Services.
                            </p>
                            
                            <h3 className="termstitle">No SMS, MMS or FAX messages</h3>
                            <p className="conditions-paragraph">
                                The service provided by Symlex Call does not and is not intended to support 
                                messaging via SMS, MMS nor FAX.
                            </p>

                            <h3 className="termstitle">PAYMENT, RATES AND ACCOUNTS</h3>
                            <h3 className="termstitle">Payment</h3>
                            <p className="conditions-paragraph">
                                In order to purchase VoIP Services or to charge your Account you shall pay 
                                Symlex Call the rates as stated on the Site, or the App as applicable depending on the 
                                type of Services that you use, by use of such payment methods as set forth on the Site 
                                or in the App, e.g. by connecting a credit card to your Account or by bank payment.
                            </p>

                            <h3 className="termstitle">Billing descriptor</h3>
                            <p className="conditions-paragraph">Payments performed by Google, Apple and SSL Commerce Bangladesh. </p>
                            
                            <h3 className="termstitle">Rates and VAT</h3>
                            <p className="conditions-paragraph">
                                The rates for the VoIP Services are published on the Site <a>http://symlexcall.com.</a> 
                                Symlex Call reserves the right to change the rates at any time without notice. If you do 
                                not wish to accept such adjustment of rates, you are entitled to terminate the Account 
                                with effect from the date on which the adjustment of rates would become effective. 
                                You agree that by continuing to use the VoIP Services, following the adjustments of 
                                the rates, you accept such adjustments.
                            </p>
                            <p className="conditions-paragraph">
                                Unless stated otherwise, all rates and charges for the VoIP Services shall be stated in USD and shall 
                                be exclusive of value added taxes (VAT) or any other applicable taxes or fees.
                            </p>

                            <h3 className="termstitle">Minute rounding</h3>
                            <p className="conditions-paragraph">
                            Symlex Call calls are charged per minute and rounded up to the 
                            next minute. For e.g., if you call for 7 minutes and 10 seconds we will round up your call to 8 minutes.</p>
                            
                            <h3 className="termstitle">Refund</h3>
                            <p className="conditions-paragraph">
                                You may request for a refund by submitting a written request in English to Symlex Call 
                                customer services at support@symlex.com. Refund requests carried out through other means 
                                shall not be eligible for the refund. For the avoidance of doubt, no refunds shall be given 
                                for services paid or credit acquired through gift or similar. Symlex Call reserves the right 
                                to deny repetitive refund requests. Any abuse by you of the terms relating to refunds hereunder 
                                shall lead to the termination of this Agreement. All payments older than 90 days are non-refundable. 
                                In exceptional cases we can refund calls that failed to connect back to your Symlex Call account, 
                                whose duration does not exceed 30 seconds and which are not older than 30 days. You can send your 
                                request to support@telz.com so that our agents evaluate your case.
                            </p>
                            
                            <h3 className="termstitle">Account and receipt</h3>
                            <p className="conditions-paragraph">
                                Symlex Call may provide an account history on the Site that includes information about your Account activity,
                                payments, calling history during a limited period, and your current Account balance , which may change from time to time. 
                                IT IS YOUR RESPONSIBILITY TO PRINT OR SAVE A COPY OF YOUR CALL SHEET AND YOUR RECEIPT AND TO RETAIN COPIES FOR YOUR RECORDS. 
                                Kolpolok Limited will use commercially reasonable efforts to correct any technical failures relating to 
                                the Account History within a reasonable time. However, your inability to view the Account History does not extend, 
                                or relieve you of, your obligation to pay any amounts owing to Kolpolok Limited.
                            </p>

                            <h3 className="termstitle">Accounting errors</h3>
                            <p className="conditions-paragraph">
                                If you believe that Symlex Call has charged your Account in error, you must submit a written request in English 
                                for refund by contacting customer services via support@symlex.com within 30 days after the date the error first 
                                appears on your Account History.
                            </p>

                            <h3 className="termstitle">Expiration of credits</h3>
                            <p className="conditions-paragraph">
                                A credit balance for the Services on the Account expires 12 months after the last activity of the Services. 
                                Credit balances that are not used within the said 12 months day period will be lost.
                            </p>
                            
                            <h3 className="termstitle">TERMINATION AND CANCELLATION</h3>
                            <h3 className="termstitle">Service termination/default</h3>
                            <p className="conditions-paragraph">
                                If you breach any of the Terms of Service, or if you are subject to any proceeding under bankruptcy, insolvency, 
                                liquidation or similar laws, you will be in default and we may, without notice to you, suspend the Services and/or 
                                terminate the Terms of Service.
                            </p>
                            <h3 className="termstitle">Cancellation of your Services</h3>
                            <p className="conditions-paragraph">
                                You may terminate the Terms of Service and cancel the Services at any time. Unless otherwise stated herein, 
                                all Services will be cancelled within 5 days of receipt of written notice from you to support@symlex.com.
                            </p>

                            <h3 className="termstitle">Limitation of Liability</h3>
                            <p className="conditions-paragraph">
                                Symlex Call is not liable for acts or omissions of any Provider, for information provided through your Device, 
                                equipment failure or modification, or causes beyond our reasonable control. We are not liable for service outages or for 
                                service limitations or interruptions. We are not liable for any accidents or incidents which result from the use of the 
                                Services by you or any other person. Our liability and the liability of any underlying Provider for any failure or mistake 
                                shall in no event exceed the VoIP Services' charges during the affected period. We and any underlying Provider are not 
                                liable for any loss of data, loss of profit, loss of goodwill or loss or damage which is indirect, incidental, punitive 
                                or consequential. We, and any underlying Provider, are not liable for economic loss or injuries to persons or property 
                                arising from the use of the Services. This paragraph shall survive termination of the Terms of Service.
                            </p>

                            <h3 className="termstitle">MISCELLANEOUS</h3>
                            <h3 className="termstitle">Assignment</h3>
                            <p className="conditions-paragraph">
                                We may assign all or part of our agreement pursuant to these Terms of Service without your consent or notice. 
                                You may not assign our agreement without our prior written approval.
                            </p>

                            <h3 className="termstitle">Indemnity</h3>
                            <p className="conditions-paragraph">
                                You agree to indemnify and hold Kolpolok Limited and its subsidiaries, affiliates, officers, agents or 
                                other partners, and employees, harmless from any claim or demand, including without limitation attorneys' 
                                fees, made by any third party due to or arising out of your (a) use of the Site or the Services including 
                                without limitation posting and submitting of any material, (b) connection to the Site or the Services (c) 
                                violation of the Terms of Service, or (d) violation of any rights of another person or entity.;
                            </p>

                            <h3 className="termstitle">Applicable law and competent court</h3>
                            <p className="conditions-paragraph">
                                The Terms of Service shall be governed by and construed in accordance with the laws of Bangladesh without giving 
                                effect to the conflict of laws or provisions of Bangladesh or your actual state or country of residence. 
                                Any dispute, controversy or claim arising out of or in relation to the Terms of Service, your relationship 
                                with Kolpolok Limited  including any non-contractual obligations, will be exclusively subject to the 
                                jurisdiction of the courts of the district of Bangladesh.
                            </p>  

                            <h3 className="termstitle">Entire Agreement</h3>
                            <p className="conditions-paragraph">
                                These Terms of Service represent the entire agreement between you and us and supersedes all prior offers, contracts, 
                                agreements and representations. The Terms of Service supersede all promises made to you by our client services agents, 
                                representatives or employees. If any part of the Terms of Service is found invalid, the balance remains enforceable.
                            </p>

                            <h3 className="termstitle">IMPORTANT — PLEASE READ CAREFULLY</h3>
                            <p className="conditions-paragraph">
                                Our communication with you and your privacy is important to us and we take great care to safeguard the ways 
                                in which any information you provide us with is collected and used.
                            </p>
                            
                            <h3 className="termstitle">Registration</h3>
                            <p className="conditions-paragraph">
                                By registration you acknowledge you are of legal age, provided true information and are fully authorized to sign on 
                                behalf of any group requesting the Services.
                            </p>
                            
                            <h3 className="termstitle">Information collection and use in general</h3>
                            <p className="conditions-paragraph">
                                Symlex Call uses your personal information to improve the Services, inter alia to offer more effective 
                                customer service. Your details may be stored and processed in Bangladesh or any other country in which 
                                Symlex Call or its affiliates, subsidiaries or agents maintain facilities. By using the Services, 
                                you consent to any such transfer of information outside your country. Kolpolok abides by applicable safe 
                                harbour frameworks regarding the collection, use, and retention of personal information.
                            </p>

                            <h3 className="termstitle">Sharing of your Personal Information</h3>
                            <p className="conditions-paragraph">
                                We will not disclose your personal information outside Kolpolok Limited and its controlled subsidiaries and affiliates 
                                without your consent, except as described in herein. Some services may be offered in conjunction with another company. 
                                In such cases, both Kolpolok Limited and the other company may receive information collected in conjunction with these 
                                co-branded services.
                            </p>
                            
                            <h3 className="termstitle">Offers and Promotions by third parties</h3>
                            <p className="conditions-paragraph">
                                We may use external advertising companies to serve ads when you have visited the Site. These companies may 
                                use information (NOT including your name, address email address or telephone number) about your visits to the 
                                Site and other websites in order to provide advertisements about goods and services of interest to you
                            </p>
                            
                            <h3 className="termstitle">Accessing your personal information</h3>
                            <p className="conditions-paragraph">To help prevent your personal information from being viewed by others, you will be required to sign 
                            in with your credentials (mobile number and password).</p>
                            <p className="conditions-paragraph">Communications, notifications etc.</p>
                            
                            <p className="conditions-paragraph">
                                Kolpolok Limited may send you messages about updates, faults, alerts and other types of issues relating to technical 
                                aspects and/or the usability of the Services. Furthermore, when you use the Services through your mobile phone, 
                                Kolpolok may use notification services to let you know of incoming calls (also when you are not actively running an App). 
                                These services may be provided by a third party.
                            </p>
                            
                            <p className="conditions-paragraph">
                                Kolpolok may occasionally ask you to provide information on your experiences of using the Services which will be used to 
                                improve the quality of the Services. You are under no obligations to provide any such information. Any and all information 
                                which is voluntarily submitted by you in relation to any survey that you take part in is used for the purposes of reviewing 
                                this feedback and improving the Services.
                            </p>
                            
                            <p className="conditions-paragraph">
                                Symex Call may from time to time contact you by email, phone, SMS or through any other appropriate information channel to 
                                keep you up to date with news about Symlex Call and the Services, including new products, campaigns and promotions.
                            </p>

                            <h3 className="termstitle">Security of your personal information</h3>
                            <p className="conditions-paragraph">
                                Symlex Call uses advanced security technologies and procedures to protect your personal information from unauthorised access. 
                                Your details are stored on computer systems with limited access in controlled facilities, and credit card numbers and passwords 
                                are protected with Secure Socket Layer (SSL) encryption when transferred over the internet. Your password helps protect your 
                                accounts and personal information, and therefore it is your responsibility to keep your password confidential. 
                                Do not share it with anyone, and make sure you log out before leaving the Site.
                            </p>

                            <h3 className="termstitle">Log files and IP addresses</h3>
                            <p className="conditions-paragraph">
                                Symlex Call uses your IP address to diagnose our server problems and to administer the Site. Excluded Numbers Premium-, 
                                special-, service- and non-geographical numbers are excluded from Symlex Call Deals.
                            </p>
                            <p className="conditions-paragraph">
                                YOU EXPRESSLY ACKNOWLEDGE THAT YOU HAVE READ THIS AGREEMENT AND UNDERSTAND THE RIGHTS, OBLIGATIONS, TERMS AND CONDITIONS 
                                SET FORTH HEREIN. BY CLICKING ON THE SIGN UP BUTTON AND/OR CONTINUING TO INSTALL THE SYMLEX CALL SOFTWARE, 
                                YOU EXPRESSLY CONSENT TO BE BOUND BY ITS TERMS AND CONDITIONS AND GRANT TO SYMLEX CALL THE RIGHTS SET FORTH HEREIN.
                            </p>
                                                      


                        </div>

                    </Col>
                </Row>
            </Container>
        </div>


    )
}

export  default PrivacyPolicy;


