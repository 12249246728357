import React from 'react'
import {Container, Row, Col} from 'react-bootstrap';
//import { Link } from 'react-router-dom';
import device from '../../images/devices.png';
import IOS from '../../images/download/iso-small.png';
import Android from '../../images/download/android-small.png';
function Download() {
function change_css()
{
    let a=document.getElementById('submenu');
    if(a.style.display==='block')
    {
        a.style.display='none'
    }
    else{
        a.style.display='block'
    }


}
    return (
        <div className="download">
<Container fluid>
    <Row>
       <Col md={1}></Col>
<Col md={5} xs={12}>
    <div className="download-left"> 
<div className="download-title">
    <p>Easy To Use & Accessible on All Platforms </p>
</div>
<div className="download-subtitle">
    <p>Open the Symlex Call app, sign up and call. It’s that Simple with different feature like vouchar recharge, lottery, getting bonuses! </p>
</div>
<div className="download-icon">
<ul>
    <li><a style={{color: "white"}} target="_blank" href="https://apps.apple.com/us/app/symlex-call/id1534287583"> <img src={IOS} alt="ISO"/> <br/> <br/> IOS</a></li>
    <li><a style={{color: "white"}} target="_blank" href="https://play.google.com/store/apps/details?id=app.kismyo.call"> <img src={Android} alt="Android"/> <br/> <br/> Android</a></li>
</ul>
</div>

<div className="button-group text-center">
   <div className="main-download">
        <a className="download-btn" target="_blank" href="https://play.google.com/store/apps/details?id=app.kismyo.call"> Android </a> 
        <span className="down-arrow" onClick={change_css}> &darr;</span>
    </div>
    <span id='submenu'>
        <a className="download-btn" target="_blank" href="https://apps.apple.com/us/app/symlex-call/id1534287583"> iPhone</a>
    </span>
</div>
</div>
</Col>

<Col md={5} xs={12}>
<div className="Download-showcase">
    <img loading="lazy" src={device} alt="device"/>
    <picture>
        <source srcSet={device} media="(min-width: 1000px)"/>
    </picture>
</div>
</Col>
</Row>
</Container>
        </div>
    )

}

export default Download
