import React from 'react'
import {Container, Row, Col} from 'react-bootstrap';
import AboutLogo from '../../images/About-logo-small.png'
function About() {
    return (
        <div className="About">
            <Container fluid>
                <Row>
                    <Col md={{offset:2 , span:2}}>
                        <div className="About-logo">
                      <img loading="lazy" src={AboutLogo} alt="About logo"/>
                        </div>
                    </Col>
                    <Col md={{ offset:1,span:5}}>
                        <div className="About-content">
                        <span className="about-color-change">Symlex Call </ span>
                         is a dialer application to make VoIP Peer to Peer Calls. 
                         The app is only designed to work on iPhone. 
                         This is a free download and anybody can use it. 
                         Select your contact and make calls right away.
                         This offers the functionality of VoIP Calls from data enabled mobile phones (3G/4G or WiFi).
                         Symlex Mobile Dialer is a SIP Softphone which allows VoIP service providers offer all the advanced VoIP calling features to their customers for making long distance VoIP calls from mobile phones. Compatible with all standard SIP Softswitch, this mobile dialer woks in Android, iOS and PC2Phone. Being a VoIP Service Provider, you can run this softphone under your own brand name and also fully customize according to your diverse business needs.

                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default About
