import React from 'react'
import {Container, Row, Col} from 'react-bootstrap';
import featureImg from '../../images/features/mobilesamll.png';

import featuresone from '../../images/features/fully_customised_user_interface.png'
import featuresTwo from '../../images/features/flexible_internet_connectivity.png'
import featuresThree from '../../images/features/make_call_more_easier.png'
import featureFour from '../../images/features/stay_ecure.png'
import featureFive from '../../images/features/refer_a_friend.png'
import featureSix from '../../images/features/earn_bonus_make_call.png'
import featureSeven from '../../images/features/add_balance.png'
import featureEight from '../../images/features/transfer_balance.png'
import featureNine from '../../images/features/voucher_recharge.png'

function Features() {
    return (
        <div className="features"  id="feature">
            <div className="feature-title text-center">
                <p>What Can We Do For You?</p>
            </div>
            <div className="features-image text-center">
                <img src={featureImg} alt="Features "/>
                <span id="Features"> </span>
            </div>
            <Container fluid>
                <Row >
                    <Col md={1}> 
                    </Col>
                    <Col md={10}> 
                    <Row> 
                    <Col md={4}>
                        <div className="single-features"> 
                        <div className="features-service-image">
                        <img src={featuresone} alt="Features  one"/>
                        </div>
                        <div className="features-single-title">
                            <p>Fully Customised User interface</p>
                        </div>
                        <div className="features-single-content">
                            <p>
                                Shows account Balance Information on mobile screen and also plays via IVR.
                                Pass calls through any firewall through Symlex Byte Saver, a tunneling software.
                            </p>
                        </div>
                        </div>
                    </Col>

                    <Col md={4}>
                        <div className="single-features"> 
                        <div className="features-service-image">
                        <img src={featuresTwo} alt="Features  two"/>
                        </div>
                        <div className="features-single-title">
                            <p>Flexible internet connectivity</p>
                        </div>
                        <div className="features-single-content">
                            <p>
                                Supports 3G/4G, GPRS, EDGE and Wi-Fi for internet connectivity.
                                Runs behind NAT or Private IP.
                            </p>
                        </div>
                        </div>
                    </Col>

                    <Col md={4}>
                        <div className="single-features"> 
                        <div className="features-service-image">
                        <img src={featuresThree} alt="Features  two"/>
                        </div>
                        <div className="features-single-title">
                            <p>Make call more easier</p>
                        </div>
                        <div className="features-single-content">
                            <p>Get comport with loudspeaker enable ,disable, call hold, Redial, Address Book support. Recent call logs show all call history.</p>
                        </div>
                        </div>
                    </Col>
                    <Col md={1}> 
                    </Col>
                    </Row>
                    </Col>
                </Row>

                <Row className="mt-5">
                <Col md={1}> 
                    </Col>
                    <Col md={10}> 
                    <Row> 
                    <Col md={4}>
                        <div className="single-features"> 
                        <div className="features-service-image">
                        <img src={featureFour} alt="Features  two"/>
                        </div>
                        <div className="features-single-title">
                            <p> Stay Secure</p>
                        </div>
                        <div className="features-single-content">
                            <p>
                                Make an encrypted Call to anywhere. No worry to keep your privacy Protected.
                            </p>
                        </div>
                        </div>
                    </Col>
                    
                    <Col md={4}>
                        <div className="single-features"> 
                        <div className="features-service-image">
                        <img src={featureFive} alt="Features  two"/>
                        </div>
                        <div className="features-single-title">
                            <p>Refer A Friend</p>
                        </div>
                        <div className="features-single-content">
                            <p>You can refer as much as you can and earn bonus from both side. It will be added with your balance.</p>
                        </div>
                        </div>
                    </Col>

                    <Col md={4}>
                        <div className="single-features"> 
                        <div className="features-service-image">
                        <img src={featureSix} alt="Features  two"/>
                        </div>
                        <div className="features-single-title">
                            <p>Earn Bonus & Make Call</p>
                        </div>
                        <div className="features-single-content">
                            <p>
                                Earn bonus from amazing feature like watching ads, spinning lottery and by daily login. Get balance and make call. 
                            </p>
                        </div>
                        </div>
                        </Col>
                    <Col md={1}> 
                    </Col>
                    </Row>
                    </Col>
                </Row>

                <Row className="mt-5">
                <Col md={1}> 
                    </Col>
                    <Col md={10}> 
                    <Row> 
                    <Col md={4}>
                        <div className="single-features"> 
                        <div className="features-service-image">
                        <img src={featureSeven} alt="Features  two"/>
                        </div>
                        <div className="features-single-title">
                            <p> Add balance</p>
                        </div>
                        <div className="features-single-content">
                            <p>
                                Get your balance from online by card payment. Stay hassle free of recharging balance.
                            </p>
                        </div>
                        </div>
                    </Col>
                    
                    <Col md={4}>
                        <div className="single-features"> 
                        <div className="features-service-image">
                        <img src={featureEight} alt="Features  two"/>
                        </div>
                        <div className="features-single-title">
                            <p>Transfer Balance</p>
                        </div>
                        <div className="features-single-content">
                            <p>
                                Has extra balance ? Trasfer now easily and more faster to your friend. No extra charge applicable.
                            </p>
                        </div>
                        </div>
                    </Col>

                    <Col md={4}>
                        <div className="single-features"> 
                        <div className="features-service-image">
                        <img src={featureNine} alt="Features  two"/>
                        </div>
                        <div className="features-single-title">
                            <p>Voucher Recharge</p>
                        </div>
                        <div className="features-single-content">
                            <p>
                                You can add balance by recharging with Voucher code. Easy to get recharge to make your call longer.
                            </p>
                        </div>
                        </div>
                        </Col>
                    <Col md={1}> 
                    </Col>
                    </Row>
                    </Col>
                </Row>

            </Container>
            <div className="access-area text-center">
         <div className="access-title">
            <p>Call with a cheaper rate.</p>
        </div>
        <div className="access-sub-title">
            <p>100+ server 20+ country </p>
        </div>
        <div className="access-content">
            <p>Explore new horizons and enjoy your favorite websites, <br/>apps and calling platforms from anywhere in the world</p>
        </div>
            </div>
        </div>
    )
}

export default Features
