import React from 'react';
//import {Link} from "react-router-dom";
import {Container, Row, Col} from 'react-bootstrap';
function  Pricing(){
    return(
        <div className="price-page">
            <Container>
                <div className="pricing-title text-center">
                    <p>Choose The Symlex Way Of Life</p>
                </div>
                <Row>
                    <Col lg={4} sm={4} xs={12}>
                        <div className="pricing-single text-center">
                            <div className="pricing-discount">
                                <p>10% OFF</p>
                            </div>
                            <div className="pricing-top-text">
                                <p>BASIC PLAN</p>
                            </div>
                            <div className="pricing-price">
                                $5.00
                            </div>
                            <div className="pricing-footer-content">
                                <p> 
                                    India <b>406</b> Min <br/>  
                                    Bangladesh <b>365</b> Min <br/>
                                    Pakistan <b>185</b> Min
                                </p>
                            </div>
                            <a href="#" target="_blank" rel="nofollow" className="btn pricing-btn">Get Started</a>

                        </div>
                    </Col>

                    <Col lg={4} sm={4} xs={12}>
                        <div className="pricing-single text-center">
                            <div className="pricing-discount">
                                <p> 26% OFF</p>
                            </div>
                            <div className="pricing-top-text">
                                <p>STANDARD PLAN</p>
                            </div>
                            <div className="pricing-price">
                                $10.00
                            </div>
                            <div className="pricing-footer-content">
                                <p> 
                                    India <b>813</b> Min <br/>  
                                    Bangladesh <b>730</b> Min <br/>
                                    Pakistan <b>370</b> Min
                                </p>
                            </div>
                            <a  href="#" target="_blank" rel="nofollow" className="btn pricing-btn">Get Started</a>
                        </div>
                    </Col>

                    <Col lg={4} sm={4} xs={12}>
                        <div className="pricing-single text-center">
                            <div className="pricing-discount">
                                <p>33% OFF</p>
                            </div>
                            <div className="pricing-top-text">
                                <p>PLATINUM PLAN</p>
                            </div>
                            <div className="pricing-price">
                                $20.00
                            </div>
                            <div className="pricing-footer-content">
                                <p> 
                                    India <b>1626</b> Min <br/>  
                                    Bangladesh <b>1460</b> Min <br/>
                                    Pakistan <b>740</b> Min
                                </p>
                            </div>
                            <a href="#" target="_blank" rel="nofollow" className="btn pricing-btn">Get Started</a>

                        </div>
                    </Col>
                </Row>

                <Row className="mt-5 mb-5">
                    <Col lg={4} sm={4} xs={12}>
                        <div className="pricing-single text-center">
                            <div className="pricing-discount">
                                <p>48% OFF</p>
                            </div>
                            <div className="pricing-top-text">
                                <p>PREMIUM PLAN</p>
                            </div>
                            <div className="pricing-price">
                                $50.00
                            </div>
                            <div className="pricing-footer-content">
                                <p> 
                                    India <b>4065</b> Min <br/>  
                                    Bangladesh <b>3650</b> Min <br/>
                                    Pakistan <b>1852</b> Min
                                </p>
                            </div>
                            <a href="#" target="_blank" rel="nofollow" className="btn pricing-btn">Get Started</a>
                        </div>
                    </Col>
                </Row>
            </Container>


        </div>
    )
}

export  default Pricing;